<template>
  <div class="order">
    <!-- 左侧导航 -->
    <div class="left-nav">
      <ul class="content">
        <li
            v-for="v in category"
            :key="v.name"
            @click="changeActive(v.id)"
            class="nav-item"
            :class="{ active: curActive == v.id}"
        >
          {{ v.name }}
        </li>
      </ul>
    </div>
    <right-nav ref="rightNav" ></right-nav>
  </div>
</template>

<script>


import rightNav from "./components/rightNav";
import leaseApi from "../../utils/leaseApi";

export default {
  components: {
    rightNav,
  },
  data() {
    return {
      curActive: null,
      category: []
    };
  },
  created() {
    this.fetchData();
  },
  computed: {},
  methods: {
    // 切换激活效果
    changeActive(id) {
      this.curActive = id;
      this.$refs['rightNav'].fetchData(id)
    },
    async fetchData() {
      const {data} = await leaseApi.getCategoryData();
      this.curActive = data[0].id
      this.category = data;
      if (this.$route.query.categoryId){
        this.curActive = this.$route.query.categoryId
      }
      this.changeActive(this.curActive)
    }
  },
};
</script>

<style lang="less" scoped>
.order {
  display: flex;
  background:#F8F8F8 ;
  padding-top: 0.33rem;
  //overflow: hidden;
  .left-nav {
    background-color: #FFFFFF;
    width: 2.78rem;
    //margin-right: 0.2rem;
    text-align: center;
    .content{
      width: 2.78rem;
      height: 100%;
      .nav-item {
        height: 1.53rem;
        font-size: 0.36rem;
        font-family: "xiaocheng";

        font-weight: 500;
        line-height: 1.53rem;
        position: relative;
        &.active {
          background: #F8F8F8;
          color: #FB884F;
          font-weight: bold;
        }
      }
    }
  }
  .right-nav{
    flex: 1;
    .detailedProduct img{
      border: 0;
    }
  }
  .active:after{
    content: "";
    position: absolute;
    width: 1.02rem;
    height: 0.19em;
    /* background: #FF9966; */
    left: 32%;
    top: 72%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: linear-gradient(90deg, rgba(255,153,102,0.6100) 40%, rgba(255,153,102,0.0100) 100%);
  }
}
/deep/.rightNavList{
  flex-wrap: wrap;
}
</style>
