<template>
  <div class="rightNav">
    <div class="myswipr">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item >
          <img src="../../images/classifyBanner1.png"/>
        </van-swipe-item>
        <van-swipe-item >
          <img src="../../images/classifyBanner2.png"/>
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="rgNavborx">
        <div class="rightNavcontent" v-for="(v,i) in brandNameList" :key="i">
          <div class="rightNavTitle">{{ v }}</div>
          <div class="rightNavList">
            <div class="detailedProduct" v-for="b in brandList[v]" :key="b.id" @click="clickDetails(b.id)">
              <img :src="b.pic"/>
              <div class="price">
                <div class="productName">{{ b.name }}</div>
                <div class="priceDetail">
                  <span>￥</span>
                  <span>{{ b.showPrice }}</span>
                  <span>/天</span>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

  </div>
</template>

<script>
import leaseApi from "../../../utils/leaseApi";
import TEMPURL from "../../../utils/tempurl";

export default {
  name: "rightNav",
  data() {
    return {
      brandList: [],
      brandNameList: [],
    }
  },
  created() {

  },
  methods: {
    // 点击跳转产品详情
    clickDetails(productId) {
      this.$router.push({
        path: "/product",
        query: {
          productId: productId
        }
      })
    },
    async fetchData(categoryId) {
      const {data} = await leaseApi.getProductGroupBrandByCategory({id: categoryId});
      this.brandNameList = data.brandNameList
      this.brandNameList.forEach(b => {
        data.brandList[b].forEach(d => {
          d.pic = TEMPURL + d.pic
        })
      })
      this.brandList = data.brandList
    }
  }
}
</script>

<style lang="less" scoped>
.rightNav {
  //flex: 1;
  //display: flex;
  //flex-wrap: wrap;
  //justify-content: space-between;
  //align-content: flex-start; //解决问题的样式
  width: 68%;
  margin: 0 auto;
  .myswipr{
    overflow: hidden;
    transform: translateY(0);
    border-radius: 0.21rem;
  }
  .rgNavborx{
    overflow: hidden;
      height: 12rem;
      overflow-y: scroll;
    padding-bottom: 2rem;
  }
  .detailedProduct {
    width: 3.3rem;
    background: #FFFFFF;
    border-radius: 0.21rem;
    margin-bottom: 0.28rem;
    padding-bottom: 0.2rem;
    overflow: hidden;
    img {
      width: 100%;
      border-radius: 0.21rem;
      height: 2.94rem;
    }

    .price {
      padding-left: 0.24rem;

      .productName {
        width: 2.63rem;
        font-size: 0.39rem;
        font-family: "xiaocheng";
        font-weight: 400;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .priceDetail {
        span:nth-child(1) {
          font-size: 0.33rem;
          font-family: "xiaocheng";

          font-weight: 400;
          color: #FB884F;
        }

        span:nth-child(2) {
          font-size: 0.47rem;
          font-family: "xiaocheng";

          font-weight: 500;
          color: #FB884F;
        }

        span:nth-child(3) {
          font-size: 0.33rem;
          font-family: "xiaocheng";

          font-weight: 400;
          color: #FB884F;
        }
      }
    }
  }
}

.rightNavList {
  display: flex;
  justify-content: space-between;
}

.rightNavTitle {
  font-size: 0.36rem;
  font-family: "xiaocheng";

  font-weight: 400;
  color: #333;
  margin:0.32rem 0rem ;
}

.my-swipe {
  //width: 6.8rem;
  //height: 2.78rem;
  //background: #E5E5E5;
  img {
    width: 100%;
    display: block;
  }
}
</style>
